import * as Api from '@/api/user/user'
import NoContent from '@/components/NoContent/NoContent'
import { PAY_STATUS, PAY_STATUS_OPTIONS } from '@/enums/API'
import { formatMoment } from '@/utils/utils'
import PapaPagination from '@/components/Pagination/Pagination'

export default {
    data () {
        return {
            tableData: [],
            pagination: {},
            noContentData: {
                pic: 'order_pic',
                tip: '您还没有订单哦~'
            },
            PAY_STATUS_OPTIONS,
        }
    },
    mounted () {
        this.getOrders()
    },
    computed: {
        prevButtonStatus () {
            return this.pagination.current_page < 2
        },
        nextButtonStatus () {
            return Boolean(!this.pagination.next)
        }
    },
    methods: {
        getOrders (page = 1) {
            let params = {
                page: page
            }
            Api.userOrder('get', params, (res, pagination) => {
                res.forEach(order => {
                    order.created_at = formatMoment(order.created_at)
                  order.billAmount = (order.bill_amount / 100).toFixed(2)
                })
                this.tableData = res
                this.pagination = pagination
            })
        },
        closeOrder (id) {
            this.$confirm('确定关闭当前订单吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Api.closeOrder(() => {
                    this.$message.success('订单已关闭~')
                    this.getOrders()
                }, '', 'v1', id)
            }).catch()
        },
        payStatus (status) {
            switch (status) {
                case PAY_STATUS.UNPAID:
                    return PAY_STATUS_OPTIONS.UNPAID.value
                case PAY_STATUS.PAID:
                    return PAY_STATUS_OPTIONS.PAID.value
                case PAY_STATUS.CLOSED:
                    return PAY_STATUS_OPTIONS.CLOSED.value
                case PAY_STATUS.REFUND:
                    return PAY_STATUS_OPTIONS.REFUND.value
                case PAY_STATUS.REFUNDING:
                    return PAY_STATUS_OPTIONS.REFUNDING.value
                case PAY_STATUS.REFUNDED:
                    return PAY_STATUS_OPTIONS.REFUNDED.value
                case PAY_STATUS.PARTPAID:
                    return PAY_STATUS_OPTIONS.PARTPAID.value
                case PAY_STATUS.CASH:
                    return PAY_STATUS_OPTIONS.CASH.value
                case PAY_STATUS.CANCEL:
                    return PAY_STATUS_OPTIONS.CANCEL.value
                case PAY_STATUS.INVALID:
                    return PAY_STATUS_OPTIONS.INVALID.value
                default:
                    return '订单状态异常' + status
            }
        },
        handlePagination (pagination) {

            this.getOrders(pagination)
        }

    },
    components: {
        NoContent,
        PapaPagination
    }
}
