<template>
    <div>
        <el-table
            v-if="tableData.length>0"
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{textAlign:'center'}"
            height="500">
            <el-table-column
                type="expand">
                <template
                    slot-scope="props">
                    <el-form label-position="left" inline>
                        <el-form-item label="课程名称" v-for="item in props.row.details" :key="item.id">
                            <div>{{ item.course.name }}</div>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column
                prop="order_sn"
                align="center"
                label="订单号">
            </el-table-column>
            <el-table-column
                prop="billAmount"
                align="center"
                label="价格"
                width="180">
            </el-table-column>
            <el-table-column
                prop="created_at"
                label="下单时间">
            </el-table-column>
            <el-table-column
                prop="status"
                align="center"
                label="状态">
                <template slot-scope="scope">
                    <span>{{ payStatus(scope.row.status) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="status"
                align="center"
                label="操作">
                <template slot-scope="scope">
                    <div v-if="scope.row.status===100" class="operation-box">
                        <router-link :to="{path:'pay',query: {order_sn:scope.row.order_sn}}">
                            <div class="check">查看</div>
                        </router-link>
                        <div class="divide">|</div>
                        <div class="close-order" @click="closeOrder(scope.row.order_sn)">关闭</div>
                    </div>
                    <router-link v-else :to="{path:'detail',query: {order_sn:scope.row.order_sn}}">
                        <div class="check">查看</div>
                    </router-link>
                </template>
            </el-table-column>
        </el-table>
        <no-content v-else :data="noContentData"></no-content>
        <papa-pagination :pagination="pagination" @handlePagination="handlePagination"></papa-pagination>
    </div>
</template>

<script>
import ListJs from './List.js'

export default ListJs
</script>

<style lang="less">
.pagination {
    text-align: right;
    margin-top: 20px;
}

.check {
    color: #29D087;
    cursor: pointer;
}

.operation-box {
    display: flex;
    justify-content: center;
}

.close-order {
    cursor: pointer;

}

.divide {
    margin: 0 10px;
}
.table_pagination {
    height: 47px;
    .pagination{
        display: flex;
        float: right;
        .pagination_current_page{
            margin: 0 10px;
            width: 2em;
        }
    }
}
</style>
